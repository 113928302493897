<template>
	<div class="buyer_contract">
		<div class="search_contract">
			<div class="search">
				<input type="text" placeholder="请输入搜索内容" v-model="keyword">
				<div class="el-icon-search"></div>
			</div>
		</div>
		<div class="contract_tabs">
			<div>合同编号</div>
			<div>合同账号</div>
			<div>合同项目</div>
			<div>创建日期</div>
			<div>签署日期</div>
			<div>状态</div>
			<div>操作</div>
		</div>
		<div class="contract_list">
			<div class="conract_item">
				<div>S23080001</div>
				<div>苏澳水槽八十八草是农村</div>
				<div>天猫转让</div>
				<div>2023/09/03</div>
				<div>2023/09/03</div>
				<div>完成</div>
				<div>编辑</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '',
			}
		},
	}
</script>

<style lang="scss">
	.buyer_contract{
		padding-top: 10px;
		.search_contract{
			padding: 0 290px;
			box-sizing: border-box;
			margin-bottom: 37px;
			.search{
				background-color: #fff;
				border-radius: 50px;
				padding: 14px 20px;
				display: flex;
				align-items: center;
				input{
					flex: 1;
					color: #30373D;
					font-size: 16px;
					border: none;
					outline: none;
					line-height: 16px;
				}
				input::placeholder{
					color: #999;
				}
				.el-icon-search{
					margin-left: 20px;
					color: rgba(48, 55, 61, 0.80);
					font-size: 19px;
					line-height: 1;
				}
			}
		}
		.contract_tabs{
			display: flex;
			align-items: center;
			background-color: #fff;
			border-radius: 10px;
			padding: 20px 11px;
			margin-bottom: 17px;
			div{
				color: #30373D;
				font-size: 15px;
				line-height: 18px;
				font-weight: 600;
				&:nth-child(1){
					width: 16.4%;
				}
				&:nth-child(2){
					width: 16.4%;
				}
				&:nth-child(3){
					width: 16.4%;
				}
				&:nth-child(4){
					width: 16.4%;
				}
				&:nth-child(5){
					width: 16.4%;
				}
				&:nth-child(6){
					width: 13.7%;
				}
				&:nth-child(7){
					flex: 1;
				}
			}
		}
		.contract_list{
			.conract_item{
				display: flex;
				align-items: center;
				padding: 17px 11px;
				background-color: #fff;
				border-radius: 8px;
				margin-bottom: 17px;
				&:last-child{
					margin-bottom: 0;
				}
				div{
					color: #30373D;
					font-size: 12px;
					line-height: 15px;
					&:nth-child(1){
						width: 16.4%;
					}
					&:nth-child(2){
						width: 16.4%;
					}
					&:nth-child(3){
						width: 16.4%;
					}
					&:nth-child(4){
						width: 16.4%;
					}
					&:nth-child(5){
						width: 16.4%;
					}
					&:nth-child(6){
						width: 13.7%;
					}
					&:nth-child(7){
						flex: 1;
					}
				}
			}
		}
		
	}
</style>